import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import uniq from 'lodash/uniq';
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { Row, Col, Button, Modal, Tooltip } from 'antd-min';
import { withRouter, GLGlobal, GLForm, GLFormComponentProps, connect, LanguageDateFormat, NotificationType, MessageHelper, RoleName, GLLocale } from 'gl-commonui';
import { SecondaryTitle, Container, ListStatesRadios, WijmoGrid, GridInputDate, GridValidators } from '@app/components';
import { StateType } from '@app/states';
import { fmtMsg, sliceMoment, isPastDate } from '@app/util/func';
import { GSSchoolAction, Weekday4Backend, Weekday4UnitPlan, SchoolClassTimeType, ListStates } from '@app/util/enum';
import { getUnitPlansForBulk, updateUnitPlansForBulk, generateUnitPlans, getUnitPlanIndex, UnitPlanIndexPrefix, setUnitPlanFieldForGrid } from '@app/states/school/class';
import * as moment from 'moment';
import { CurriculumType, SchoolClassModel } from '@app/service/class';
import { GenerateUnitPlan } from './components/generate-unit-plan';
import { isOnlySpecificRole, DateHelper, IconFont, lazyInject, TYPES } from '@app/util';
import { HeadersVisibility, SelectionMode, KeyAction, FlexGrid, CellRangeEventArgs, CellType } from 'wijmo/wijmo.grid';
import { GridRef } from '@app/components/grid/grid';
import { DataType, toggleClass } from 'wijmo/wijmo';
import "./unitplanbulk.less";
import { IRegionService } from "@app/service/admin/regions";


const { Grid, Column } = WijmoGrid;
interface UnitPlansPageProps {
    schoolClasses: any[]
    loading: boolean
    total: number;
    activeCnt: number;
    futureCount: number;
    futureAnnualOuter: boolean
    school: any
    getUnitPlansForBulk: (d) => void
    updateUnitPlansForBulk: (d) => void
    generateUnitPlans: (d) => void
}

interface UnitPlansPageStates {
    isNextBtnDisabled?: boolean
    isGeneratePlanVisible?: boolean
    isPlanModified?: boolean
    isCpClassesVisible?: boolean
    isUnitPlanHistoryVisible?: boolean
    listState?: any
    schoolClass?: any
    isValid?: boolean
    currentRow?: number
    columnWidth?: number
    edited?: boolean
    enforceUnitPlanSpacing: boolean;
}

@withRouter
@GLForm.create()
@connect(({ schoolClass: { list, loading, total, activeCnt, futureCount }, school: { current: school } }: StateType) => ({
    schoolClasses: list,
    loading,
    total,
    activeCnt,
    futureCnt: futureCount,
    school
}), {
        getUnitPlansForBulk,
        updateUnitPlansForBulk,
        generateUnitPlans,
    }, ({ state: { unitPlans, model, futureCnt , school}, own: { match: { params: { regionId, schoolId, campusId } } }, dispatch: { getUnitPlansForBulk, updateUnitPlansForBulk } }) => ({
        getUnitPlansForBulk: getUnitPlansForBulkFunc(schoolId, campusId, getUnitPlansForBulk),
        updateUnitPlansForBulk: updateUnitPlansForBulkBy(regionId, schoolId, campusId, school.appLockState, updateUnitPlansForBulk),
        futureCount: futureCnt || 0,
        model: { unitPlans, model }
    }))
export class UnitPlanBulkPage extends Component<UnitPlansPageProps & RouteComponentProps<any> & GLFormComponentProps, UnitPlansPageStates> {
    gridRef = React.createRef<GridRef>();
    grid = {} as FlexGrid;
    isSaving = false;
    mediaQuery;
    @lazyInject(TYPES.IRegionService)
    service: IRegionService;
    constructor(props) {
        super(props);
        this.state = {
            isNextBtnDisabled: false, // obj && obj.isCreatedClass,
            isGeneratePlanVisible: false,
            isPlanModified: false,
            isCpClassesVisible: false,
            isUnitPlanHistoryVisible: false,
            listState: ListStates.Active,
            schoolClass: { unitPlan: [] },
            isValid: false,
            currentRow: 0,
            columnWidth: this.getColumnMinSize(this.watchGridResized()),
            edited: false,
            enforceUnitPlanSpacing: true,
        };
        this.onGenerateUnitPlans = this.onGenerateUnitPlans.bind(this);
        this.generateUnitPlans = this.generateUnitPlans.bind(this);
        this.closeEndDateEditor = this.closeEndDateEditor.bind(this);
    }
    componentWillMount() {
        this.service
            .getEnforceUnitPlanSpacing(this.props.match.params.regionId)
            .then(status => {
                this.setState({ enforceUnitPlanSpacing: status });
            })
            .catch(error => {
                //console.log(error);
            });
    }
    componentDidMount() {
        this.getDataSource(ListStates.Active);
    }
    componentWillUnmount() {
        this.mediaQuery && this.mediaQuery.removeListener(this.setColumnMinSize.bind(this));
    }
    componentDidUpdate(prevProps,prevState){
        if(GLGlobal.intl.locale !== prevProps.langLoaded){
            this.gridRef.current.grid.collectionView.refresh();
        }
    }
    watchGridResized() {
        this.mediaQuery = window.matchMedia('(max-width: 1600px)');
        this.mediaQuery.addListener(this.setColumnMinSize.bind(this));
        return this.mediaQuery;
    }
    getColumnMinSize(e) {
        let columnWidth = 150;
        if (e.matches) {
            columnWidth = 110;
        }
        return columnWidth;
    }
    setColumnMinSize(e) {
        this.setState({ columnWidth: this.getColumnMinSize(e) });
    }
    getDataSource(type: ListStates) {
        let options: any = {};
        if (type == ListStates.Active) {
            options.disabled = false;
        } else if (type == ListStates.Future) {
            options.future = true;
        }
        this.props.getUnitPlansForBulk(options);
    }
    getGridSource() {
        return this.gridRef.current.grid.collectionView.sourceCollection as any[]
    }
    validateRow(row, time = 500) {
        setTimeout(() => {
            this.gridRef.current.validation.validateRow(row);
            this.gridRef.current.grid.invalidate(true);
        }, time)
    }
    validate() {
        this.gridRef.current.validation.validate()
        this.gridRef.current.grid.invalidate(true);
    }
    handleEdits(flex: FlexGrid, event: CellRangeEventArgs, dataItem: any) {
        const { startUnit, maxUnit } = dataItem;
        const { binding } = flex.columns[event.col];
        const cancelEdit = () => {
            event.cancel = true;
        };

        if (binding.startsWith(UnitPlanIndexPrefix)) {
            const { school: { allowGenerateUnitPlan } } = this.props;
            const frozenColumns = getfrozenColumns(allowGenerateUnitPlan);
            const disabled = dataItem.isfutureAnnualOuter || isOnlySpecificRole(RoleName.globalHead)
            const withInUnitRange = Math.abs(maxUnit) - Math.abs(startUnit) + 1;
            const readonlyRange = event.col >= (withInUnitRange + frozenColumns);
            const unitPlanIndex = event.col - frozenColumns;
            const isPastPlan = dataItem[binding] ? !dataItem.unitPlan[unitPlanIndex].editable && !GLGlobal.isActionValid(GSSchoolAction.UncheckMindaysPerUnit) && isPastDate(dataItem[binding]) : false;
            const setCellReadonly = (readonly) => {
                if (dataItem.unitPlan.length > unitPlanIndex) {
                    dataItem.unitPlan[unitPlanIndex].readonlyCell = readonly;
                }
            };
            setCellReadonly(false);
            if (disabled || readonlyRange || isPastPlan) {
                setCellReadonly(true);
                cancelEdit();
            }
        }
    };
    cellEditEnded(flex: FlexGrid, event: CellRangeEventArgs, dataItem) {
        const { binding } = flex.columns[event.col];

        if (binding.startsWith(UnitPlanIndexPrefix)) {
            const syncUnitPlan = (index) => {
                dataItem.unitPlan[index].startDate = dataItem[binding] ? moment(dataItem[binding]).add(moment.duration(moment(dataItem[binding]).utcOffset(), 'minutes')).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}) : null;
            }
            const unitPlanIndex = binding.substring(UnitPlanIndexPrefix.length);
            const isChanged = DateHelper.toLocalString(dataItem.unitPlan[unitPlanIndex].startDate) != DateHelper.toLocalString(dataItem[binding]);
            if (isChanged && !this.state.edited) {
                this.setState({edited: true});
            }
            syncUnitPlan(unitPlanIndex);
            this.validateRow(event.row);
        }
    };

    onGenerateUnitPlans(schoolClass, row) {
        const { school: { allowGenerateUnitPlan } } = this.props;
        if (!(allowGenerateUnitPlan && !schoolClass.isfutureAnnualOuter)) {
            return;
        }
        if (Object.keys(excludeDateMissedError(schoolClass)).length > 0) {
            MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: SchoolLocale.UnitPlanBulkGenerateErrorMsg }));
        }
        else {
            this.setState({ isGeneratePlanVisible: true, schoolClass, currentRow: row });
        }
    }
    generateUnitPlans({ schoolClassId, beginUnit, startDate, endDate }) {
        const { schoolClasses, generateUnitPlans, match: { params: { schoolId } } } = this.props;
        const model = schoolClasses.find(sc => sc.id == schoolClassId);
        const unitPlans = model.unitPlan;
        const [validMinDaysPerUnit, suggestMinDaysPerUnit] = getMinDaysPerUnit(model,this.state.enforceUnitPlanSpacing);
        const daysofweek = uniq(model.schoolClassTime.filter(sct => sct.type == SchoolClassTimeType[SchoolClassTimeType.TSI] && sct.daysOfWeek != Weekday4Backend.Sunday && sct.daysOfWeek != Weekday4Backend.Saturday)
            .map(sct => sct.daysOfWeek - 1));
        const oldUnitPlans = unitPlans.map( up => { return {...up} });
        generateUnitPlans && generateUnitPlans({
            schoolId,
            schoolClassId,
            beginUnit,
            startDate: `${moment(startDate).format('YYYY-MM-DD')}T00:00:00Z`,
            endDate,
            unitPlans,
            minDaysPerUnit: suggestMinDaysPerUnit,
            daysofweek: daysofweek.length == 0 ? Object.keys(Weekday4UnitPlan).filter(day => day.length == 1).map(day => parseInt(day)) : daysofweek,
            successCallback: (unitPlans) => {
                this.setGeneratedEndDate(schoolClassId, endDate);
                this.resetUnitPlans(schoolClassId, unitPlans);
                this.validateRow(this.state.currentRow);
                this.closeEndDateEditor();
                const planChanged = oldUnitPlans.filter(oup => {
                    const newUP = unitPlans.find( up => up.unit == oup.unit);
                    return !newUP || DateHelper.toLocalString(oup.startDate) != DateHelper.toLocalString(newUP.startDate)
                }).length > 0;
                if (planChanged && !this.state.edited) {
                    this.setState({ edited: true });
                }
            }
        })
    }
    setGeneratedEndDate(schoolClassId, endDate) {
        const schoolClass = this.getGridSource().find(d => d.id == schoolClassId);
        schoolClass.endDate = moment(endDate);
    }
    resetUnitPlans(schoolClassId, unitPlans) {
        if (unitPlans) {
            const schoolClass = this.getGridSource().find(d => d.id == schoolClassId);
            unitPlans.forEach(up => {
                const oup = schoolClass.unitPlan.find(oup => oup.unit == up.unit);
                oup.startDate = up.startDate;
            });
            setUnitPlanFieldForGrid(schoolClass);
        }
    }
    closeEndDateEditor() {
        if (this.state.isGeneratePlanVisible) { this.setState({ isGeneratePlanVisible: false }); }
    }
    getMaxUnitPlanCnt() {
        const { schoolClasses } = this.props;
        const cnt = schoolClasses.map(sc => sc.unitPlan.length).sort((pre, next) => pre - next).pop() || 1;
        return cnt > 0 ? cnt : 1;
    }
    getGenrerateColumnRender(isEdit = false) {
        const { school: { allowGenerateUnitPlan } } = this.props;
        const cellRender = (value, item, context) => {
            return allowGenerateUnitPlan && !item.isfutureAnnualOuter
                ? <a href="javascript:void(0)" ><IconFont type="glsunitplan" /></a>
                : <a href="javascript:void(0)" className='unitplanbulk__unit-plan-generate-cell-disabled'><IconFont type="glsunitplan" /></a>;
        };
        return isEdit ? (context, value, item) => cellRender(value, item, context) : cellRender;
    }
    getUnitPlanColumns(columnWidth, enforceUnitPlanSpacing) {
        const dateFormat = getDateFormatTemplate();
        const cnt = this.getMaxUnitPlanCnt();
        const { edited } = this.state;
        return new Array(cnt).fill(0).map((_, index) => (
            <Column
                key={index}
                isReadOnly={false}
                binding={getUnitPlanIndex(index)}
                dataType={DataType.Date}
                renderHeader={() => <Tooltip title={fmtMsg({ id: SchoolLocale.BulkUnitColumnTooltip })}>
                                        {getUnitPlanOrdinalName(index)}
                                    </Tooltip>}
                render={(value) => DateHelper.toLocalString(value)}
                renderEditor={(context, value) => {
                    const [_, suggestMinDaysPerUnit] = getMinDaysPerUnit(context.item, enforceUnitPlanSpacing);
                    const defaultPickerDate = value ? moment(value) : getDefaultStartDate(context.item.unitPlan, context.item.unitPlan[index].unit, suggestMinDaysPerUnit, context.item.startDate);
                    return <GridInputDate
                        format={dateFormat}
                        context={context}
                        initialized={sender => {
                            if (defaultPickerDate) {
                                sender.isDroppedDownChanged.addHandler(sender => {
                                    setTimeout(() => sender.calendar.displayMonth = defaultPickerDate.toDate())
                                })
                            }
                        }}
                        min={DateHelper.toLocal(context.item.startDate).toDate()}
                        max={context.item.endDate ? DateHelper.toLocal(context.item.endDate).toDate() : null}
                        itemFormatter={(date, element) => {
                            const isHighlight = defaultPickerDate ? moment(date).isSame(defaultPickerDate, 'day') : false;
                            toggleClass(element, 'unitplan-defaultpickerdate', isHighlight);
                        }}
                    />;
                }}
                validators={[
                    GridValidators.minDate("startDate", SchoolLocale.UnitPlanBulkValidStartDateLaterClass),
                    unitPlanStartDateValidator(index, this.state.enforceUnitPlanSpacing)
                ]}
                isRequired={false}
            />
        ))
    }
    cancel() {
        const { history } = this.props;
        if (this.state.edited) {
            Modal.confirm({
                title: fmtMsg({ id: GSAdminLocale.ModelTitleConfirmation }),
                content: fmtMsg({ id: SchoolLocale.UnitPlanBulkCancelMsg }),
                okText: fmtMsg({ id: GSAdminLocale.ModelButtonYes }),
                okType: "danger",
                cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo }),
                onOk() {
                    history.goBack();
                },
                onCancel() {},
            });
        }
        else {
            history.goBack();
        }
    }
    save() {
        this.validate();
        this.isSaving = true;
        this.saveCallback();
    }
    saveCallback(isValid?) {
        if (this.isSaving && (isValid || this.state.isValid)) {
            this.isSaving = false;
            //this.setState({edited: false});
            this.updateUnitPlans();
        }
    }
    updateUnitPlans() {
        this.props.updateUnitPlansForBulk(this.getGridSource());
    }
    renderWijmoRow(s, e) {
        if (e.panel.cellType == CellType.Cell
            && s.columns[e.col].binding === getUnitPlanIndex(0)) {
            toggleClass(e.cell, 'unitplanbulk__unit-plan-startdate-empty', !moment(s.rows[e.row].dataItem.unitPlan[0].startDate).isValid());
        }
    }
    render() {
        const { schoolClasses, loading, total, activeCnt, futureCount, school: { allowGenerateUnitPlan }, history } = this.props;
        const { schoolClass, isGeneratePlanVisible, isPlanModified, columnWidth, edited } = this.state;
        return (
            <Container fullWidth className="bce unitplanbulk">
                <SecondaryTitle title={SchoolLocale.UnitPlanBulkTitle}></SecondaryTitle>
                <ListStatesRadios
                    // hideCnts
                    showInActiveRadio={false}
                    allCnt={activeCnt + futureCount}
                    activeCnt={activeCnt}
                    futureCount={futureCount}
                    value={this.state.listState}
                    onChange={(state) => {
                        this.setState({ listState: state });
                        this.getDataSource(state);
                    }}>
                </ListStatesRadios>
                <Grid
                    ref={this.gridRef}
                    itemsSource={schoolClasses}
                    loading={loading}
                    headersVisibility={HeadersVisibility.Column}
                    allowSorting={false}
                    selectionMode={SelectionMode.CellRange}
                    keyActionTab={KeyAction.Cycle}
                    pagination={false}
                    beginningEdit={this.handleEdits.bind(this)}
                    cellEditEnded={this.cellEditEnded.bind(this)}
                    cellDisableHandler={this.handleEdits.bind(this)}
                    allowDelete={false}
                    valid={isValid => {
                        this.setState({ isValid }, () => {
                            this.saveCallback(isValid);
                        });
                    }}
                    frozenColumns={getfrozenColumns(allowGenerateUnitPlan)}
                    bordered
                    loadedRows={_ => {
                        setTimeout(() => this.validate(), 1000);
                    }}
                    initialized={grid => {
                        const self = this;
                        grid.addEventListener(grid.hostElement, 'click', e => {
                            const ht = grid.hitTest(e);
                            if (grid.columns[ht.col].binding === 'edit'
                                && ht.target.parentElement
                                && ht.target.parentElement.classList.contains('anticon')) {
                                self.onGenerateUnitPlans(self.getGridSource()[ht.row], ht.row)
                            }
                        })
                    }}
                    formatItem={this.renderWijmoRow.bind(this)}
                >
                    <Column
                        minWidth={150}
                        maxWidth={200}
                        isReadOnly={true}
                        binding="name"
                        header={fmtMsg({ id: SchoolLocale.BulkClassName })}
                    />
                    {allowGenerateUnitPlan && <Column
                        maxWidth={170}
                        binding="edit"
                        isReadOnly={false}
                        cssClass="unitplanbulk__unit-plan-generate-cell"
                        header={fmtMsg({ id: SchoolLocale.UnitPlanBulkGenerate })}
                        render={this.getGenrerateColumnRender()}
                        renderEditor={this.getGenrerateColumnRender(true)}
                    />}
                    <Column
                        minWidth={120}
                        maxWidth={columnWidth}
                        isReadOnly={true}
                        binding="startDate"
                        header={fmtMsg({ id: SchoolLocale.BulkStartDate })}
                        render={(value) => DateHelper.toLocalString(value)}
                        dataType={DataType.Date}
                    />
                    <Column
                        minWidth={100}
                        maxWidth={100}
                        isReadOnly={true}
                        binding="startUnit"
                        header={fmtMsg({ id: SchoolLocale.BulkStartUnit })}
                    />
                    {this.getUnitPlanColumns(columnWidth,this.state.enforceUnitPlanSpacing)}
                </Grid>
                <GenerateUnitPlan
                    visible={isGeneratePlanVisible}
                    unitPlans={schoolClass.unitPlan}
                    unitPlanIsModified={isPlanModified}
                    schoolClass={schoolClass}
                    displayClassName={true}
                    onSave={this.generateUnitPlans}
                    onCancel={this.closeEndDateEditor}
                />
                <Row type="flex" gutter={8} justify="end">
                    <Col>
                        <Button className="unitplanbulk__btn" onClick={_ => this.cancel()}>
                            {fmtMsg(GLLocale.Cancel)}
                        </Button>
                        <Button className="unitplanbulk__btn" type="primary" disabled={!edited || !checkSaveBtnEnabled()} onClick={_ => this.save()}>
                            {fmtMsg(SchoolLocale.BulkSave)}
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}
function getfrozenColumns(allowGenerateUnitPlan) {
    return allowGenerateUnitPlan ? 4 : 3;
}
function excludeDateMissedError(schoolClass) {
    const errors = { ...schoolClass.errors };
    const emptyError = fmtMsg({ id: SchoolLocale.UnitPlanBulkValidStartDateMissing }, { unit: '' });
    return Object.keys(errors).reduce((errs, key) => {
        if (errors[key] != emptyError) {
            errs[key] = errors[key]
        }
        return errs;
    }, {})
}
function checkSaveBtnEnabled() {
    return GLGlobal.isActionValid(GSSchoolAction.SavePlan) && !isOnlySpecificRole(RoleName.globalHead)
}
function isReadonlyCell(unitPlans: any[], index) {
    return unitPlans.length > index && unitPlans[index].readonlyCell;
}
const unitPlanStartDateValidator = (curIndex, enforceUnitPlanSpacing) => (value: Date, dataItem: any): ReturnType<any> => {
    const curName = getUnitPlanOrdinalName(curIndex);
    const unitPlans = [...dataItem.unitPlan].map((d, index) => ({ ...d, index }));
    const enforce = enforceUnitPlanSpacing;
    if (!value) {
        return validateStartDateMissing(curIndex, curName, value, unitPlans);
    }
    if(enforceUnitPlanSpacing === true) {
        const [minDaysPerUnit, _] = getMinDaysPerUnit(dataItem,enforce);
        return validateDateComparison(curIndex, curName, value, unitPlans, minDaysPerUnit);
    }

    return;
};
function validateStartDateMissing(curIndex, curName, value, unitPlans: any[]) {
    if (!value) {
        if (
            curIndex == 0
            ||
            unitPlans.filter(up => up.index > curIndex).some(up => up.startDate)
        ) {
            return fmtMsg({ id: SchoolLocale.UnitPlanBulkValidStartDateMissing }, { unit: curName });
        }
    }
    return;
}
function validateDateComparison(curIndex, curName, value, allPlans: any[], minDaysPerUnit) {
    const unitPlans = allPlans.filter(d => d.startDate);
    const before = unitPlans.filter(up => up.index < curIndex).pop();
    const after = unitPlans.filter(up => up.index > curIndex).shift();
    if (before && moment(value).isSameOrBefore(before.startDate, 'day')) {
        return fmtMsg({ id: SchoolLocale.UnitPlanBulkValidStartDateLater }, { pre: getUnitPlanOrdinalName(before.index), cur: curName });
    }
    if (after && moment(value).isSameOrAfter(after.startDate, 'day')) {
        return fmtMsg({ id: SchoolLocale.UnitPlanBulkValidStartDateEarlier }, { next: getUnitPlanOrdinalName(after.index), cur: curName });
    }
    return validateDateMinimumInterval(value, before, after, minDaysPerUnit);
}
function validateDateMinimumInterval(value, before, after, minDaysPerUnit) {
    if (before && minDaysPerUnit && sliceMoment(moment(value)).diff(sliceMoment(before.startDate), 'days') < minDaysPerUnit) {
        return fmtMsg({ id: minDaysPerUnit > 1 ? SchoolLocale.UnitPlanValidMinimumDays : SchoolLocale.UnitPlanValidMinimumDay }, { value: minDaysPerUnit });
    }
    if (after && minDaysPerUnit && sliceMoment(after.startDate).diff(sliceMoment(moment(value)), 'days') < minDaysPerUnit) {
        return fmtMsg({ id: minDaysPerUnit > 1 ? SchoolLocale.UnitPlanValidMinimumDays : SchoolLocale.UnitPlanValidMinimumDay }, { value: minDaysPerUnit });
    }
    return;
}
export function getDateFormatTemplate(): string {
    return LanguageDateFormat[GLGlobal.intl.locale].replace(/D/g, "d").replace(/Y/g, "y");
}

function updateUnitPlansForBulkBy(regionId, schoolId, campusId, schoolAppLockState, updateUnitPlansForBulk) {
    return (data) => updateUnitPlansForBulk({ data, regionId, schoolId, campusId, schoolAppLockState });
}

function getUnitPlansForBulkFunc(schoolId, campusId, getUnitPlansForBulk) {
    return (options) => getUnitPlansForBulk({ ...options, schoolId, campusId });
}

function getMinDaysPerUnit({ tsiLessonsPerWeek, curriculumType }: SchoolClassModel, enforceUnitPlanSpacing: boolean) {
    if (tsiLessonsPerWeek === undefined || curriculumType === undefined) return [1, 1];

    if (Number(curriculumType) === CurriculumType.LittleSEED) return [1, 1];

    const minDaysPerUnit = Math.ceil(210 / tsiLessonsPerWeek) - 4;
    return [
        (!GLGlobal.isActionValid(GSSchoolAction.UncheckMindaysPerUnit) && enforceUnitPlanSpacing === false) || GLGlobal.isActionValid(GSSchoolAction.UncheckMindaysPerUnit)
            ? 1
            : minDaysPerUnit,
        minDaysPerUnit
    ];
}

function validDate({ date = () => defaultValue, valid = () => true, format = (d) => d, defaultValue = null }: { date: () => moment.Moment, valid?: () => boolean, format?: (d) => any, defaultValue?: any }) {
    return valid() && date() && date().isValid() ? format(date()) : defaultValue
}

function getDefaultStartDate(dataSource, unit, minDaysPerUnit, schoolClassStartDate = null) {
    const preUnits = dataSource.filter((item) => item.unit < unit && item['startDate']);
    const pre = preUnits && preUnits[preUnits.length - 1];
    return validDate({
        valid: () => (minDaysPerUnit && pre) || schoolClassStartDate != null,
        date: () => pre ? pre['startDate'] : moment(schoolClassStartDate),
        format: (date) => moment(date, 'YYYY/MM/DD').add(pre ? minDaysPerUnit : 0, 'day'),
    })
}

function getUnitPlanOrdinalName(index) {
    if (index <= 7) {
        return getUnitPlanOrdinalNameByLocaleKey(index);
    }
    const remainder = ++index % 10;

    // 11, 12, 13 are exception cases where their default indicator is not used.
    const ordinal = {
        1: {
            indicator: 'st',
            exception: {
                index: 11,
                indicator: 'th'
            }
        },
        2: {
            indicator: 'nd',
            exception: {
                index: 12,
                indicator: 'th'
            }
        },
        3: {
            indicator: 'rd',
            exception: {
                index: 13,
                indicator: 'th'
            }
        }
    }

    const indicator = ordinal[remainder] ? (ordinal[remainder].exception.index == index ? ordinal[remainder].exception.indicator : ordinal[remainder].indicator) : 'th';

    return `${index}${indicator} unit`;
}

function getUnitPlanOrdinalNameByLocaleKey(index) {
    return fmtMsg(`class_unitplan.bulk.unitcolumn${++index}`);
}
